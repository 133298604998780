<template>
  <div class="page flex" style="justify-content: space-between">
    <el-form
      class="medium-form"
      size="medium"
      label-width="110px"
      ref="ruleForm"
    >
      <el-form-item label="任务名称：">
        <el-input type="text" v-model="form.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="任务类型：" prop="type">
        <el-select :disabled="modificationLimited" placeholder="请选择" v-model="form.type">
          <el-option
            v-for="item in [{ id: 'jielong', name: '接龙' }]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 不同类型的任务内容不一样，当前原型为接龙的任务类型内容，点击选择类型后自动切换列表内容 -->
      <el-form-item label="内容：">
        <br />
        <task-detail :form-data.sync="form"></task-detail>
      </el-form-item>
      <!-- 用户范围为：所有会员，指定职务。按社团云现有组件直接用，图中只是展示而已 -->
      <el-form-item label="用户范围：">
        <div class="flex-col">
          <el-radio-group
            class="flex-align-center"
            v-model="form.scope"
            style="height: 36px"
            :disabled="modificationLimited"
          >
            <el-radio v-for="au in authority" :key="au.id" :label="au.id">{{
              au.name
            }}</el-radio>
          </el-radio-group>

          <positions-selector
            v-if="!modificationLimited"
            v-show="form.scope === 1"
            v-model="form.position_ids"
            hash
          ></positions-selector>
        </div>
      </el-form-item>
      <!-- 完成条件后续再做，现阶段可以先不做 -->
<!--      <el-form-item label="完成条件："> </el-form-item>-->

      <el-form-item label="截止时间：" prop="deadline_start_time">
        <el-date-picker
          style="flex: 1; width: 100%"
          v-model="deadlineTime"
          type="datetime"
          value-format="timestamp"
          placeholder="请选择"
          clearable
          :disabled="modificationLimited"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="消息通知：">
        <!-- 开启、关闭 -->
        <el-radio-group
          v-model="form.notification_enable"
          style="height: 36px"
          class="flex-align-center"
          :disabled="modificationLimited"
        >
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <el-checkbox-group :disabled="modificationLimited" style="margin-top: 1px" v-model="messageTypes">
          <el-checkbox :label="1">
            公众号模板消息
<!--            <el-popover trigger="hover" placement="top">-->
<!--              <template #reference>-->
<!--                &lt;!&ndash; 鼠标移入感叹号出现 图片示例 &ndash;&gt;-->
<!--                <i class="el-icon-warning-outline"></i>-->
<!--              </template>-->
<!--              &lt;!&ndash; TODO：内容 &ndash;&gt;-->
<!--              内容-->
<!--            </el-popover>-->
          </el-checkbox>
          <el-checkbox :label="2">
            短信通知
<!--            <el-popover trigger="hover" placement="top">-->
<!--              <template #reference>-->
<!--                &lt;!&ndash; 鼠标移入感叹号出现 图片示例 &ndash;&gt;-->
<!--                <i class="el-icon-warning-outline"></i>-->
<!--              </template>-->
<!--              &lt;!&ndash; TODO：内容 &ndash;&gt;-->
<!--              内容-->
<!--            </el-popover>-->
          </el-checkbox>
          <el-checkbox :label="3">
            站内通知
<!--            <el-popover trigger="hover" placement="top">-->
<!--              <template #reference>-->
<!--                &lt;!&ndash; 鼠标移入感叹号出现 图片示例 &ndash;&gt;-->
<!--                <i class="el-icon-warning-outline"></i>-->
<!--              </template>-->
<!--              &lt;!&ndash; TODO：内容 &ndash;&gt;-->
<!--              内容-->
<!--            </el-popover>-->
          </el-checkbox>
          <el-checkbox :label="4"> 首页弹窗提醒 </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button size="medium" @click="onCancel">返回</el-button>
      <el-button
        v-if="!loading"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存</el-button
      >
      <el-button
        v-if="!loading && !form.status && form.id"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onRelease('ruleForm')"
        >发布</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import MediaWall from '../../common/components/MediaWall'
import fileUpload from '../mixin/fileUpload'
import PositionsSelector from '../../common/components/PositionsSelector'
import FixedActionBar from '../../../base/layout/FixedActionBar'
import { getTaskDetail, releaseTask, saveTask } from '../api/detail'
import TaskDetail from '../components/TaskDetail'

export default {
  components: { TaskDetail, FixedActionBar, PositionsSelector, MediaWall },
  mixins: [fileUpload],
  data() {
    return {
      loading: false,
      saveLoading: false,
      form: {
        id: '',
        name: '',
        type: 'jielong', // jielong：接龙，默认选中第一个（目前只有这一个）
        status: 0, // 0: 保存，1:发布
        content: '', // 内容详情
        images: [], // 内容图片
        files: [], // 内容附件
        scope: 0, // 范围（0: 全选，1:指定职务）
        to_all_user: 0, // 全员时细分（0: 只发给会员，1:发给全体用户（包括游客））
        position_ids: [], // 职务数组（混淆ID）
        deadline_time: 0, // 截止时间
        notification_enable: 1, // 通知开关
        wechat_official_account_msg: 1, // 公众号模板消息
        sms_msg: 0, // 短信消息
        system_msg: 0, // 系统应用内消息
        index_dialog_msg: 0, // 首页弹窗
      },
      authority: [
        {
          id: 0,
          name: '所有会员',
        },
        {
          id: 1,
          name: '指定职务',
        },
      ],
    }
  },
  computed: {
    // 编辑受限
    modificationLimited() {
      return !!this.form.status // 1：已发布
    },
    /**
     *     "wechat_official_account_msg": 1, // 公众号模板消息
     "sms_msg": 0, // 短信消息
     "system_msg": 0, // 系统应用内消息
     "index_dialog_msg": 0 // 首页弹窗
     */
    messageTypes: {
      get() {
        const types = []
        if (this.form.wechat_official_account_msg) {
          types.push(1)
        }
        if (this.form.sms_msg) {
          types.push(2)
        }
        if (this.form.system_msg) {
          types.push(3)
        }
        if (this.form.index_dialog_msg) {
          types.push(4)
        }
        return types
      },
      set(val) {
        this.form.wechat_official_account_msg = val.includes(1) ? 1 : 0
        this.form.sms_msg = val.includes(2) ? 1 : 0
        this.form.system_msg = val.includes(3) ? 1 : 0
        this.form.index_dialog_msg = val.includes(4) ? 1 : 0
      },
    },
    deadlineTime: {
      get() {
        if (this.form.deadline_time === 0) return ''
        return this.form.deadline_time * 1000
      },
      set(val) {
        if (!val) {
          this.form.deadline_time = 0
          return
        }
        this.form.deadline_time = val / 1000
      },
    },
  },
  created() {
    const { id } = this.$route.params
    if (id) {
      this.getDetail(id)
    }
  },
  methods: {
    getDetail(id) {
      this.loading = true
      getTaskDetail({ id })
          .then(({ data, msg }) => {
            this.form = data
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
    },
    onRelease() {
      releaseTask({ id: this.$route.params.id })
        .then(({ code, data, msg }) => {
          if (code) {
            this.$message.success(msg)
          }
          this.saveLoading = false
          // TODO: 刷新？
        })
        .catch(() => {
          this.saveLoading = false
        })
    },
    onSave() {
      this.saveLoading = true
      saveTask({
        ...this.form,
      })
        .then(({ code, data, msg }) => {
          if (code) {
            this.$message.success(msg)
            this.onCancel()
          } else {
          }
          this.saveLoading = false
        })
        .catch(() => {
          this.saveLoading = false
        })
    },
    onCancel() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.medium-form .el-textarea textarea {
  resize: vertical;
  min-height: 1.5 * 10em !important;
}
</style>
